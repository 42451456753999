import React, { useState, createRef, useRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { API } from "../../queries/api";
import { Box, Button, Modal } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import { VisuallyHiddenInput } from "../../constants/hiddenIput";

interface ImageUploadProps {
	type: string;
	destinationID?: string;
	children?: React.ReactNode;
	onUpload: (image: string) => void;
}

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
};

const ImageUpload: React.FC<ImageUploadProps> = ({
	type,
	destinationID,
	children,
	onUpload,
}) => {
	const [image, setImage] = useState();
	// const [cropData, setCropData] = useState("#"); // use for preview showing
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const cropperRef = createRef<ReactCropperElement>();

	const handleChildClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const onChange = (e: any) => {
		e.preventDefault();
		setLoading(true);
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			setImage(reader.result as any);
			handleOpen();
			setLoading(false);
		};
		reader.readAsDataURL(files[0]);
	};

	const cropImageHandle = () => {
		if (typeof cropperRef.current?.cropper !== "undefined") {
			const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
			const resizedCanvas = document.createElement("canvas");
			const resizedContext = resizedCanvas.getContext("2d");

			// Resize the canvas to 512x512
			resizedCanvas.width = 512;
			resizedCanvas.height = 512;

			// Draw the cropped image onto the resized canvas
			if (resizedContext) {
				resizedContext.drawImage(croppedCanvas, 0, 0, 512, 512);
			}

			// Convert the resized canvas to data URL
			const resizedImageDataUrl = resizedCanvas.toDataURL("image/jpeg");
			// setCropData(resizedImageDataUrl);
			handleUpload(resizedImageDataUrl);
		}
	};

	const handleUpload = async (croppedImage: any) => {
		try {
			setLoading(true);
			const blob = await fetch(croppedImage).then((r) => r.blob());
			const formData = new FormData();
			formData.append("file", blob, "chat_image.jpg");
			let query = `image?type=${type}`;

			if (destinationID) {
				query += `&destinationID=${destinationID}`;
			}

			const response = await API.multipart(query, formData, (percent: string) =>
				console.log(percent)
			);

			if (response.status === 200) {
				onUpload(response.data.result);
			}
		} catch (error) {
			console.error("Error uploading image", error);
			// Handle error, e.g., display an error message
		}
		handleClose();
		setLoading(false);
	};

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-start",
					padding: "16px 0",
				}}
			>
				{loading ? (
					<LoadingButton
						loading
						variant="outlined"
						disabled
						sx={{ width: "200px" }}
					>
						<span>disabled</span>
					</LoadingButton>
				) : children ? (
					<div onClick={handleChildClick}>
						{children}
						<VisuallyHiddenInput
							ref={fileInputRef}
							type="file"
							onChange={onChange}
						/>
					</div>
				) : (
					<Button
						component="label"
						variant="contained"
						color="info"
						startIcon={<CloudUploadIcon />}
					>
						Upload image
						<VisuallyHiddenInput type="file" onChange={onChange} />
					</Button>
				)}
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: "80%" }}>
					<h2 id="parent-modal-title">Crop image</h2>
					<br />
					<Cropper
						ref={cropperRef}
						style={{ height: "60vh", width: "100%" }}
						zoomTo={1}
						aspectRatio={1}
						preview=".img-preview"
						src={image}
						viewMode={1}
						minCropBoxHeight={10}
						minCropBoxWidth={10}
						background={false}
						responsive={true}
						autoCropArea={1}
						checkOrientation={false}
						guides={true}
						zoomable={false}
					/>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							padding: "16px 0",
						}}
					>
						<Button variant="outlined" onClick={handleClose} sx={{ mx: 1 }}>
							Cancel
						</Button>
						{loading ? (
							<LoadingButton
								loading
								variant="outlined"
								disabled
								sx={{ width: "200px" }}
							>
								<span>disabled</span>
							</LoadingButton>
						) : (
							<Button variant="contained" onClick={cropImageHandle}>
								Upload image
							</Button>
						)}
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default ImageUpload;
